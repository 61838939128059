<template>
  <BaseCard
    title="datos del proveedor"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <ProviderProfileForm
      ref="provider-profile-form"
      v-model="provider"
    />
    <hr class="mx-2">
    <b-card-title class="m-0 py-1 px-2">
      <b-row align-v="center">
        <b-col
          class="font-weight-bold d-flex align-items-center"
          cols="auto"
        >
          INFORMACIÓN BANCARIA Y DE PAGO
        </b-col>
      </b-row>
    </b-card-title>
    <ProviderBillingForm
      ref="provider-billing-form"
      v-model="provider"
    />
  </BaseCard>
</template>

<script>
import ApiRestService from '@/api/base-api'
import ProviderBillingForm from '@/components/providers/form/ProviderBillingForm.vue'
import ProviderProfileForm from '@/components/providers/form/ProviderProfileForm.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    ProviderProfileForm,
    ProviderBillingForm,
  },
  data() {
    return {
      loading: false,
      provider: {},
    }
  },
  methods: {
    async handleSaveButtonClick() {
      const formsValidation = await Promise.all([
        this.$refs['provider-profile-form'].validateForm(),
        this.$refs['provider-billing-form'].validateForm(),
      ])

      if (formsValidation.some(valid => !valid)) {
        return
      }

      this.loading = true
      try {
        const response = await ApiRestService.create(this.provider, this.$data.$constants.RESOURCES.RESOURCE_PROVIDERS)
        await this.$router.replace({ name: 'viewProvider', params: { id: response.data.id } })
      } catch (error) {
        if (error.response.data?.error) {
          this.$toast.error(error.response.data.error)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
